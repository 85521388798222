import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';
import Markdown from 'marked-react';
import css from './ListingPage.module.css';
import SectionShipping from './SectionShipping';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    description,
    policies,
    listingTitle,
    listing,
    onManageDisableScrolling,
    author,
  } = props;
  const shippingCountries = author?.attributes?.profile?.publicData
    ?.shippingCountries
    ? author.attributes.profile.publicData?.shippingCountries
    : [];
    const renderer = {
    
    };

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage
          id="ListingPage.descriptionTitle"
          values={{ listingTitle }}
        />
      </h2>
      <div className={css.description}>
      
  
         <Markdown breaks={true} renderer={renderer}>{description}</Markdown> 
   
      </div>
      {listing.attributes.publicData.baseCategory == 'events' &&
      !listing.attributes.publicData.isReferral ? (
        <div>
          <h2 className={css.descriptionTitle}>Date</h2>
          {new Date(listing.attributes.publicData.eventDate).toDateString()}
        </div>
      ) : null}
      {!listing.attributes.publicData.isReferral && (
        <SectionShipping
          shippingCountries={shippingCountries}
          productType={props.productType}
        />
      )}
      {policies ? (
        <div
          className={css.shopPoliciesLink}
          onClick={() => setIsModalOpen(true)}
        >
          Shop Policies
        </div>
      ) : null}

      <Modal
        id="ListingPage.policies"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        isNotFullScreenMobile={true}
      >
        <div className={css.shopPoliciesContent}>
          <h2>
            <FormattedMessage id="ShopDetails.PoliciesTitle" />
          </h2>
          <p>{policies}</p>
        </div>
      </Modal>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
